<template>
  <div>
    <div class="dropdown">
      <button
        class="btn btn-sm dropdown-toggle btn-actions"
        type="button"
        id="dropdownMenuButton"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      ></button>

      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <a
          class="dropdown-item"
          v-for="(action, i) in actions"
          :key="i"
          @click="$emit(action.event)"
        >
          <i class="fa" :class="action.icon" :style="{ color: action.color }"></i>
          {{ action.text }}
        </a>
      </div>
    </div>
  </div>
</template>
<script type="text/javascript">
export default {
  props: {
    actions: Array,
  },
};
</script>
<style type="text/css" scoped>
.dropdown button {
  color: var(--standard-color);
}
.dropdown-item {
  padding: 10px;
  cursor: pointer;
}
.dropdown-item:not(:last-child) {
  border-bottom: 1px inset #ddd;
}
.dropdown-item i {
  margin-right: 10px;
}
</style>
