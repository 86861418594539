<template functional>
  <div class="content-box">
    <div class="icon-box"><i class="fa" :class="props.icon"></i></div>
    <span class="content-box-title">{{ props.title }}</span>
    <slot></slot>
  </div>
</template>
<script type="text/javascript">
export default {
  props: {
    title: String,
    icon: String,
  },
};
</script>
<style type="text/css" scoped>
.content-box {
  background-color: var(--standard-background-color);
  color: var(--standard-font-color);
  padding: 20px;
  border-radius: 8px;
  position: relative;
  margin-top: 30px;
  width: 100%;
}
.icon-box {
  background-color: var(--standard-color);
  display: flex;
  width: 70px;
  height: 70px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  position: absolute;
  left: 20px;
  top: -25px;
}
.icon-box i {
  font-size: 2rem;
  color: white;
}
.content-box-title {
  padding-left: 90px;
  font-size: 1.3rem;
  margin-bottom: 20px;
  font-weight: 700;
  display: block;
  min-height: 30px;
}
</style>
