<template>
  <div class="spinner_wrapper" v-if="loading">
    <h2>Aguarde um momento...</h2>
    <div class="spinner-grow text-danger" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-grow text-warning" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-grow text-info" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</template>
<script type="text/javascript">
export default {
  props: {
    loading: {
      default: false,
    },
  },
};
</script>
<style type="text/css" scoped>
.spinner_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  text-align: center;
  vertical-align: middle;
  padding-top: 45vh;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.9);
}
</style>
