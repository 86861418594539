<template>
  <div>
    <spinner :loading="processing" />
    <content-box icon="fa-box-open" title="Produtos">
      <div class="show_on_pc">
        <div class="table_container">
          <table class="table table-striped">
            <thead>
              <th>Produto</th>
              <th class="center">Status</th>
              <th></th>
            </thead>
            <tbody>
              <tr v-for="product in GetterProductsWithActions" :key="product.id">
                <td>{{ product.name }}</td>
                <td
                  v-html="$options.filters.status_active_inactive(product.status)"
                  class="center"
                ></td>
                <td>
                  <data-action
                    :actions="product.actions"
                    @block_record="toggleStatus(product.id)"
                    @unblock_record="toggleStatus(product.id)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </content-box>
  </div>
</template>
<script type="text/javascript">
import { mapState, mapActions, mapGetters } from 'vuex';
import ContentBox from '@/theme/boxes/ContentBox.vue';
import DataAction from '@/theme/components/DataActions.vue';
import Spinner from '@/features/Spinner/Spinner.vue';
// import filters from './filters';

export default {
  components: {
    ContentBox,
    DataAction,
    Spinner,
  },
  // filters,
  data() {
    return {
      processing: false,
    };
  },
  methods: {
    ...mapActions('products', ['ActionListProducts', 'ActionToggleStatus']),
    async listProducts() {
      await this.ActionListProducts();
    },
    async toggleStatus(productId) {
      this.processing = true;
      await this.ActionToggleStatus({ productId });
      await this.listProducts();
      this.processing = false;
    },
  },
  computed: {
    ...mapState('products', ['products', 'filter']),
    ...mapGetters('products', ['GetterProductsWithActions']),
  },
  async mounted() {
    this.processing = true;
    await this.listProducts();
    this.processing = false;
  },
};
</script>
<style type="text/css">
.small_info {
  font-size: 0.85rem;
  font-weight: 900;
  color: black;
  margin-top: 5px;
  display: inline-block;
}
</style>
